/* ==================== Define import ==================== */
/* ---------- React ---------- */
import React, { useState, useEffect } from 'react';
/* ---------- CSS ---------- */
import styled from 'styled-components'
/* ---------- useForm ---------- */
import { useForm } from 'react-hook-form';
/* ---------- useNavigate ---------- */
import { useNavigate, useLocation } from "react-router-dom"
import S1 from '../../Images/S1.svg';
import { ContactInputBtn } from './ContactInputButton';
import { HeadBlock } from '../../HeadBlock';

/*====== Define styles ======*/
// import Flow from "../../Images/Contact.png"
/*------ Styled Components ------*/

const ContactDev = styled.div`
	width:100%;
	margin: 70px auto;
	min-height:70vh;
    height: fit-content;
	@media(max-width: 600px){
  		/* font-size:0.7rem;
		width: 80%; */
		margin: 0 auto;
	}
`

//フォーム説明文
const FormExplain = styled.p`
	font-size: 1rem;
	letter-spacing: 0.1rem;
	line-height: 20px;
	width:100%;
	text-align: center;
	margin: 40px 0;
	font-family: 'Noto Sans JP', sans-serif;
	@media(max-width: 699px){
  		font-size:0.7rem;
		width: 80%;
		text-align: left;
		margin: 20px auto;
		
	}
`;
//記入欄の配置
const EntryForm = styled.table`
	max-width: 1170px;
	width: 20%;
	margin: 0 auto;
	font-weight: normal;
	font-family: 'Noto Sans JP', sans-serif;
	/* padding-left:150px; */
	
	@media(max-width: 699px){
		width: 90%;
	}
	@media(min-width: 700px) and (max-width: 1170px) {
		width: 60%;
	}
`;

//フォームの項目
const FormName = styled.th`
	font-weight: normal;
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;
    width: 300px;
    font-size: 0.7rem;
    letter-spacing:0.1em;
    border-bottom:  1px solid white;
    font-family: 'Noto Sans JP', sans-serif;
	display:inline-block;
@media(max-width: 1170px){
		display:block;
  		border-collapse: collapse;
  		width:90%;
		  margin: 0.5rem auto;
          text-align:left;
	}
`;
const Inputarea = styled.td`
margin: 0 0 0.5rem 0;

@media screen and (max-width: 1170px) {
	display: block;
	/* margin: 0.5rem 0 0 0; */
	}

	
`
const Sinput = styled.input`
	font-size:0.8rem;
	margin: 0.2rem 0.5rem 0.2rem ;
`

//inputのデザイン
const EntryInput = styled.input`
	border: none;
	height: 35px;
	width: 500px;
	font-size:0.7rem;
	padding-left: 15px;
	background: #D9D9D9;
	letter-spacing:0.1em;
	margin:1rem 0 0 0.5rem;
	display:block;
	.transparent-text {
    color: transparent;
  }
	@media(max-width: 1170px){
  		border-collapse: collapse;
  		padding-left:5px;
  		width:90%;
		  margin: 0.5rem auto;
		 
	}
	
`;
//テキストエリア
const EntryTextarea = styled.textarea`
	border: none;
	height: 180px;
	width: 500px;
	font-size:0.7rem;
	padding-left: 15px;
	background: #D9D9D9;
	letter-spacing:0.1em;
	margin:1rem 0 0 0.5rem;
	padding-top:10px;
	display:block;
	@media(max-width: 1170px){
		/* width: 500px; */
  		border-collapse: collapse;
  		padding-left:5px;
  		width:90%;
		  margin: 0.5rem auto;
		 
	}
	
`;
//エラーメッセージ
const ErrorMessage = styled.div`
	margin: 0 10%;
	color:red;
	/* padding:0 15px 0 15px; */
	font-size:0.7rem;
	margin: 0.2rem 0 0.5rem 0.5rem;
	@media screen and (max-width:1170px){
		margin: 0.2rem 0 1rem 5%;
    }
	
`;
//同意文の配置
const Agreementplace = styled.div`
	max-width: 1170px;
	margin:40px auto 0 auto;
	text-align:center;
	width:100%;
	font-size:0.7rem;
`;
const CheckInput = styled.input`
	width: 7px;
	transform: scale(2);
	margin: 0 10px 0 0;
`;
//同意分のデザイン
const Agree = styled.div``;

//ボタンの配置
const ButtonPlace = styled.div`
	max-width:1170px;
	margin: 20px auto 0 auto;
	height: 60px;

	
`;

const ButtonAll = styled.div`
	margin: 0 auto ;
	text-align: center;
`;


const Slink = styled.a`
width: fit-content;
	display: block;
	margin: 0 auto 20px auto;
	color: black;
`;

const Slabel = styled.p`
	display: block;
	font-size: 0.7rem;
	letter-spacing: 0.1rem;
	@media(max-width: 1170px){
	margin: 0 0 0 5%;
	}
`
const Ssvg = styled.svg`

	height: 60px;
	
	@media screen and (max-width:899px){
		width: 60%;
    }
	@media screen and (max-width:699px){
		width: 90%;
		margin:50px auto;
    }
`
const Frow = styled.div`
	display: flex;
    justify-content: center;
	@media screen and (max-width:699px){
		margin: -50px auto;
    }
`;


/*====== Define global variables/functions ======*/
/*------ global variables ------*/
/*------ global functions ------*/
/*====================*/
/*======  Main  ======*/
/*====================*/
export const ContactForm = () => {
	// useFormフックを使い、registerとhandleSubmitを取得する。
	// registerは、フォームのフィールドを登録することで、バリデーションを機能させる。
	// handleSubmitは、submitイベントの制御に関わる。
	const { register, handleSubmit, getValues,  setValue, formState: { errors } } = useForm({
		mode: 'onSubmit',
		criteriaMode: "all",
		shouldFocusError: false,
	});
	// submitイベントが発生して、かつバリデーションが成功した場合に実行する関数。
	const onSubmit = (data) => {
		navigate('/contactconfirmform', {
			state: {
				SelectValues,
				NameValues,
				FuriganaValues,
				CompanyValues,
				PhoneValues,
				EmailValues,
				ContentsValues,
			}
		})
	};
	//submitボタンを押した時、入力内容確認画面を表示させる
	// 入力データを取得
	const SelectValues = getValues('select');
	const NameValues = getValues('name');
	const FuriganaValues = getValues('furigana');
	const CompanyValues = getValues('company');
	const PhoneValues = getValues('phone');
	const EmailValues = getValues('email');
	const ContentsValues = getValues('contents');
	// 画面遷移
	const navigate = useNavigate();
	//チェックボタンによる、確認ボタンON/OFF
	const [isChecked, setIsChecked] = useState(false)

	const toggleCheckbox = () => {
		console.log(isChecked)
		setIsChecked(!isChecked)
	};
	// eslint-disable-next-line no-empty-pattern
	const [] = useState(true);
	const radioButtons = [
		{ id: "work", label: "お仕事のご依頼・ご相談", value: "お仕事のご依頼・ご相談", required: true, },
		{ id: "recruit", label: "採用について", value: "採用について", required: false },
		{ id: "other", label: "その他のお問い合わせ", value: "その他のお問い合わせ", required: false },
	];
	

	// 入力情報保持
	const location = useLocation();
	
	// 入力情報更新
	useEffect(() => {
		if (location.state !== null) {
			setValue("select", location.state.setSelectValues)
			setValue("name", location.state.setNameValues)
			setValue("furigana", location.state.setFuriganaValues)
			setValue("company", location.state.setCompanyValues)
			setValue("phone", location.state.setPhoneValues)
			setValue("email", location.state.setEmailValues)
			setValue("contents", location.state.setContentsValues)

			navigate(location.pathname, { replace: true });

		} else {
		}
	}, [location.pathname, location.state, navigate, setValue])
	/*--------------------------*/
	/*------ Start Render ------*/
	/*--------------------------*/
	return (
		<>
			<ContactDev>
				<HeadBlock title ="CONTACT - 入力フォーム - 株式会社レイヴン" description="株式会社レイヴンのお問い合わせページです。お仕事のご依頼。ご相談、採用について、その他のお問い合わせも受け付けております。" path="Contact" />
			
				<Frow><Ssvg  viewBox="0 0 982 90"><use xlinkHref={`${S1}#S1`}></use></Ssvg></Frow>
				<FormExplain>内容を入力の上、「入力内容確認」ボタンをクリックして下さい。</FormExplain>
				
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<EntryForm>
						<thead>
							<tr>
								<FormName>お問い合わせ種類（必須）</FormName>
								<Inputarea>
									{radioButtons.map((radio) => {
										const { id, label, value} = radio;
										return (
											<Slabel key={id}>
												<Sinput
													id="select"
													type="radio"
													value={value}
													name="contactkind"
													{...register("select", { required: true })}
													/>
												{label}
											</Slabel>
										);
									})}
									
									{errors.select && <ErrorMessage>選択してください</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="name"> 名前（必須）</FormName>
								<Inputarea>
									<EntryInput oninput="this.style.color = 'transparent';" id="name" placeholder="山田 太郎" name="name" {...register('name', { required: '名前を記入してください', maxLength: { value: 20, message: '最大20文字です' } })} />
									{errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="furigana">フリガナ（必須）</FormName>
								<Inputarea>
									<EntryInput oninput="this.style.color = 'transparent';"  id="furigana" placeholder="ヤマダ タロウ" {...register('furigana', { required: 'フリガナを記入してください', pattern: { value: /^[ァ-ヶー　]+$/, message: 'カタカナのみで記入してください' }, maxLength: { value: 20, message: '最大20文字です' } })} />
									{errors.furigana && <ErrorMessage>{errors.furigana.message}</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="company">会社名・学校名（必須）</FormName>
								<Inputarea>
									<EntryInput id="company" placeholder="〇〇株式会社" {...register('company', { required: '会社名・学校名を記入してください', maxLength: { value: 44, message: '最大44文字です' } })} />
									{errors.company && <ErrorMessage>{errors.company.message}</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="phone">電話番号（必須） </FormName>
								<Inputarea>
									<EntryInput id="phone" placeholder="0009999444" {...register('phone', { required: '電話番号を記入してください', pattern: { value: /^\d*$/, message: '半角数字のみで記入してください' }, maxLength: { value: 11, message: '最大11文字です' } })} />
									{errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="email">メールアドレス（必須） </FormName>
								<Inputarea>
									<EntryInput id="email" placeholder="sample@samle.com" {...register('email'
										, {
											required: 'メールアドレスを記入してください'
											
											, pattern: { value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/, message: '入力形式が不正なメールアドレスです' }
											, maxLength: { value: 32, message: '32文字以下にして下さい' }
											, minLength: { value: 3, message: `3文字以上にして下さい` }
										})} />
									{errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
								</Inputarea>
							</tr>
							<tr>
								<FormName htmlFor="contents">お問い合わせ内容（必須）</FormName>
								<Inputarea>
									<EntryTextarea id="contents" placeholder="お問い合わせ内容入力" {...register('contents', { required: 'お問い合わせ内容を記入してください', maxLength: { value: 500, message: '最大500文字です' } })} />
									{errors.contents && <ErrorMessage>{errors.contents.message}</ErrorMessage>}
								</Inputarea>
							</tr>
						</thead>
					</EntryForm>
					<Agreementplace>
						<Agree>
							<Slink href="/privacy">個人情報保護方針</Slink>
							<CheckInput type="checkbox" name="agree" id="agreeCheck" onChange={() => toggleCheckbox()} />個人情報保護方針に同意します

						</Agree>
					</Agreementplace>
					<ButtonAll>
						<ButtonPlace>
							<ContactInputBtn 
                        	       type="submit"
                        	       btn_text="入力内容確認"
                        	       hoverBackgroundColor="#FBE04F"
                        	       disabled={!isChecked}
                        	       width='12rem'
                        	       height='3rem'
                        	   />
						</ButtonPlace>
					</ButtonAll>
				</form>
			</ContactDev>
		</>
	);
}; 
