/*====== Define import ======*/
import React from "react";
import styled from "styled-components";
/* ------ components ------ */
import { Header } from "../../Components/Common/Header";
import { SubVisual } from "../../Components/Common/SubVisual";
import { SectionTitleAnime } from "../../Components/Common/SectionTitleAnime";
import { CompanyTable } from "../../Components/Company/CompanyTable";
import { AppravalTable } from "../../Components/Company/ApprvalTable.jsx";
import { Map } from "../../Components/Company/Map";
import { Footer } from "../../Components/Common/Footer";
import {WACC2} from "../../Components/Common/WACC2"
import { HeadBlock } from "../../HeadBlock";
/* ------ img ------ */
import img from "../../Images/Company/RaaLicon.png";
import OrganizationChart from "../../Images/Company/組織図.svg";
import componyVisual from "../../Images/3U5A2819.JPG"
import privacy from "../../Images/Conpany_Appral/privacy.jp.svg"//プライバシーマーク
import ISO27001 from "../../Images/Conpany_Appral/ISO27001.svg"//ISO2001
import ISO9001 from "../../Images/Conpany_Appral/ISO9001.svg"//ISO9001
import labor from "../../Images/Conpany_Appral/労働者.svg"//ISO9001

/* ------ styled components ------ */
const SCompanyInfoWrapper = styled.div`
padding: 1vh 0 0 0;
`;

const SinfoWrapper = styled.div`
max-width: 1170px;
width: 100%;
margin: 0 auto;
font-size: 1em;
margin-top:80px ;

`;

const Stable = styled.table`
margin: 0 0 80px;
`;

// ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー

const SWrapper = styled.ul`
    max-width: 1170px;
    width: auto;
    margin: 80px auto;
`;

const MainTitle = styled.div`
margin-bottom: 30px;
`;
const TableWidth = styled.table`
margin: 0 auto;
@media (max-width: 1190px) {
    width: 90%;
}
`;
const STableWidth = styled.div`
margin: 0 auto;
@media (max-width: 1190px) {
    width: 90%;
}
`;

// const Td = styled.td`
// padding: 1rem;
// font-weight:bold;
// border-bottom: 1px solid #ebecf0;
// line-height: 1.5em;
// font-size: 1.1rem;
// @media (max-width: 900px) {
//     padding: 0 10vw 0 1rem;
//     font-size: 0.8rem;
//     /* width: 20rem; */
// }
// @media (max-width: 400px) {
//     font-size: 0.8rem;
//     /* width: 10rem; */
//     padding: 0 10vw 0 1rem;
// }
// `;
// const Td2 = styled.td`
// border-bottom: 1px solid #ebecf0;
// font-weight:bold;
// line-height: 1.5em;
// font-size: 1.1rem;
// /* #### 900px以下 ### */
// @media (max-width: 900px) {
//     font-size: 0.8rem;
//     width: 20rem;
// }
// @media (max-width: 400px) {
//     font-size: 0.8rem;
//     width: 15rem;
// }
// `;
// margin.padding---------------------------
// const Smargin3 = styled.div`
//     margin: 3vh 0;
// `;


// const Smargin10 = styled.div`
//     margin: 10vh 0;
// `;




/*====================*/
/*====== Main ======*/
/*====================*/
export const Company = () => {

    const msg1 = "〒150-0002 \n東京都渋谷区渋谷二丁目9番8号 日総第25ビル 10F \nTEL 03-6427-4321\nFAX 03-6427-4331 "
    const text = msg1.split(/(\n)/).map((item, text) => {
        return (
            <p key={text}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });
    const msg6 = "〒460-0003 \n愛知県名古屋市中区錦二丁目20番15号 広小路クロスタワー 14F \nTEL 052-228-6589\nFAX 052-228-6590 "
    const text2 = msg6.split(/(\n)/).map((item, text2) => {
        return (
            <p key={text2}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });
    const msg2 = "三菱東京UFJ銀行 国立支店 \n三菱東京UFJ銀行 渋谷支店\n三井住友銀行 渋谷支店 "
    const bnk = msg2.split(/(\n)/).map((item, bnk) => {
        return (
            <p key={bnk}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });
    const msg3 = "トヨタコネクティッド株式会社\nトヨタ自動車株式会社\n日本特殊陶業株式会社\n株式会社野村総合研究所"
    const company = msg3.split(/(\n)/).map((item, company) => {
        return (
            <p key={company}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });
    const msg4 = "主要取引先\n(五十音順 敬称略) "
    const cpname = msg4.split(/(\n)/).map((item, text) => {
        return (
            <p key={text}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });
    const service = "PM/PMO事業\n開発事業\nビジネス構築事業"
    const service1 = service.split(/(\n)/).map((item, service1) => {
        return (
            <p key={service1}>
                {item.match(/\n/) ? <br /> : item}
            </p>
        )

    });

    /*====== variables ======*/
    //会社概要
    const companyInfoList = [
        { id: 1, th: "社名", td: "株式会社レイヴン" },
        { id: 2, th: "所在地 (本社)", td: text },
        { id: 3, th: "所在地 (名古屋営業所)", td: text2 },
        { id: 4, th: "設立", td: " 2014年12月22日" },
        { id: 5, th: "決算期", td: " 11月" },
        { id: 6, th: "資本金", td: "6,000万円" },
        { id: 7, th: "役員", td: " 代表取締役　山田 浩一郎" },
        { id: 9, th: "主な事業内容", td: service1 },
        { id: 10, th: "主な取引銀行", td: bnk },
        { id: 11, th: cpname, td: company },
        { id: 14, th: "組織図", td: OrganizationChart, img: true }
    ]

    //各種認可取得
    const AuthorizationList = [
        { id: 1, td: "認証名", td1: "登録番号", td2:'',},
        { id: 2, td: "プライバシーマーク", td1: "19001230", td2: privacy, img: true },
        { id: 3, td: "労働者派遣事業許可証", td1: "派 13-307982", td2: labor, img: true },
        { id: 4, td: "ISO27001", td1: "MSA-SA-601", td2: ISO27001, img: true, },
        { id: 5, td: "ISO9001", td1: "MISA-QS-4914", td2: ISO9001, img: true },
    ]

    return (
        <>
            <HeadBlock title="COMPANY - 株式会社レイヴン" description="レイヴンの所在地、資本金、役員、主な事業内容、各種認証取得、子会社情報などがご覧いただけます。" path="TrainingG21" />

            <Header color="white" />
            <SubVisual
                Image={componyVisual}
                sub_titleEn="COMPANY"
                subTitleJa="会社情報"
            />

            <SCompanyInfoWrapper>

                <SinfoWrapper>
                    <MainTitle>
                        <SectionTitleAnime
                            TitleName="COMPANY"
                            fontSize="2rem"
                            fontFamily="'Roboto Condensed', sans-serif;"
                            backGroundcolor="black"
                        />
                        <SectionTitleAnime
                            TitleName="INFORMATION"
                            fontSize="2rem"
                            fontFamily="'Roboto Condensed', sans-serif;"
                            backGroundcolor="black"
                        />
                        <br />
                        <SectionTitleAnime
                            TitleName="会社概要"
                            fontSize="0.8rem"
                            fontFamily=" 'Noto Sans JP', sans-serif"
                            backGroundcolor="black"
                        />
                    </MainTitle>

                    <TableWidth>
                        <tbody key="companytable">
                            {
                                companyInfoList.map((Item, index) => {
                                    return (
                                        <CompanyTable key={index} th={Item.th} td={Item.td} img={Item.img} />
                                    )
                                })
                            }
                        </tbody>
                    </TableWidth>
                </SinfoWrapper>
            </SCompanyInfoWrapper >
            <SCompanyInfoWrapper>
                <SinfoWrapper>
                    <MainTitle>

                        <SectionTitleAnime
                            TitleName="CERTIFICATION"
                            fontSize="2rem"
                            fontFamily="'Roboto Condensed', sans-serif;"
                            backGroundcolor="black"
                        />
                        <br />
                        <SectionTitleAnime
                            TitleName="各種認証取得"
                            fontSize="0.8rem"
                            fontFamily=" 'Noto Sans JP', sans-serif"
                            backGroundcolor="black"
                        />
                    </MainTitle>
                    <STableWidth>
                        <Stable>

                                {
                                    AuthorizationList.map((Item, index) => {

                                        return (
                                            <AppravalTable key={index} th={Item.th} td={Item.td} td1={Item.td1} td2={Item.td2} img={Item.img} />
                                        )
                                    })
                                }
                        </Stable>
                    </STableWidth>
                </SinfoWrapper>
            </SCompanyInfoWrapper>
            <Map />


            <SWrapper>

                <MainTitle>
                    <SectionTitleAnime
                        TitleName="SUBSIDIARY"
                        fontSize="2rem"
                        fontFamily="'Roboto Condensed', sans-serif;"
                        backGroundcolor="black"
                    />
                    <SectionTitleAnime
                        TitleName="INFORMATION"
                        fontSize="2rem"
                        fontFamily="'Roboto Condensed', sans-serif;"
                        backGroundcolor="black"
                    />
                    <br />

                    <SectionTitleAnime
                        TitleName="子会社情報"
                        fontSize="0.8rem"
                        fontFamily=" 'Noto Sans JP', sans-serif"
                        backGroundcolor="black"
                    />
                </MainTitle>
                <WACC2
                    title="株式会社 レイヴンRaaL"
                    img={img}
                    alt={img}
                    href="https://ravenraal.com/"
                    // position="center"
                    
                />
            </SWrapper>
            <Footer color="white" />
        </>
    );
};
